import { PropsWithChildren, useEffect, useState } from 'react';
import CollapseContext from './Collapse.context';
import * as Style from './Collapse.style';
import CollapseBody from './CollapseBody';
import CollapseHeader from './CollapseHeader';

type CollapseProps = PropsWithChildren & {
  disabled?: boolean,
  show?: boolean,
};

const Collapse = ({
  children,
  disabled = false,
  show = false,
}: CollapseProps) => {
  const [isShow, setIsShow] = useState(show);

  const handleToggle = () => !disabled && setIsShow((isShow) => !isShow);

  useEffect(() => {
    setIsShow(disabled);
  }, [disabled]);

  return (
    <CollapseContext.Provider
      value={{
        isShow,
        handleToggle,
      }}
    >
      <Style.Container>
        {children}
      </Style.Container>
    </CollapseContext.Provider>
  );
};

export default Object.assign(Collapse, {
  Body: CollapseBody,
  Header: CollapseHeader,
});
