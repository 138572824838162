import { useEffect, useState } from 'react';
import * as Style from './Sidebar.style';
import Collapse from '@/components/common/Collapse/Collapse';
import { mdDown } from '@/exportables/styles';
import useResize from '@/hooks/client/useResize';

const SideBar = () => {
  const [isMobileSize, setIsMobileSize] = useState(false);
  const { windowSize } = useResize();

  useEffect(() => {
    if (windowSize?.innerWidth! < Number(mdDown)) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, [windowSize]);

  return (
    <>
      <Style.Banner>
        <div id="community-app-banner-vertical" />
      </Style.Banner>
      <Style.Survey>
        <Collapse show disabled={!isMobileSize}>
          <Style.SurveyHeader>
            <h5>
              프로그래머스 커뮤니티,<br />함께 만들어가요
            </h5>
          </Style.SurveyHeader>

          <Style.SurveyBody>
            <h6>설문조사에 참여해 의견을 나눠주세요</h6>
            <Style.SurveyBtn
              href="https://form.typeform.com/to/EmhNx91r"
              target="_blank"
              backgroundColor="black"
            >
              설문 참여하기
            </Style.SurveyBtn>
          </Style.SurveyBody>
        </Collapse>
      </Style.Survey>
    </>
  );
};

export default SideBar;
