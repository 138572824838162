import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import ImgSurvey from './banner-survey.png';
import CustomButton from '@/components/common/Button';
import Collapse from '@/components/common/Collapse/Collapse';
import {
  belowMdDown,
  belowMobile,
  blueGrey20,
  blueGrey900,
  flexColumnStartY,
  font,
  THEME_COLORS,
} from '@/exportables/styles';
import { remCalc } from '@/exportables/utils';

export const Banner = styled.div`
  width: 100%;
  position: relative;
  max-height: 400px;
  overflow: hidden;

  a {
    display: block;
    border-radius: ${remCalc('8')};

    .cont {
      padding: ${remCalc('24')};
      .tag {
        span {
          background: rgba(74, 74, 74, 0.4);
          border-radius: ${remCalc('4')};
          color: ${THEME_COLORS.white};
          font-size: ${remCalc('12')};
          font-weight: 700;
          line-height: 1;
          padding: ${remCalc('8')} ${remCalc('8')};
          display: inline-block;
        }
      }
      h1 {
        font-size: ${remCalc('18')};
        line-height: 140%;
        letter-spacing: -${remCalc('0.8')};
        color: ${THEME_COLORS.white};
        padding-top: ${remCalc('8')};
        padding-bottom: ${remCalc('12')};
        font-weight: 700;
      }
      h2 {
        font-size: ${remCalc('13')};
        line-height: 140%;
        letter-spacing: -${remCalc('0.8')};
        color: ${THEME_COLORS.white};
      }
    }

    .img {
      padding: ${remCalc('8 0 0 0')};
      text-align: center;
      img {
        width: 100%;
      }
    }
  }

  ${belowMdDown(css`
    a {
      display: flex;
      width: 100%;
      justify-content: space-between;
      
      .cont {
        padding: ${remCalc('16 20 20')};
        br {
          display: none;
        }
        h1 {
          font-size: ${remCalc('16')};
          padding-top: ${remCalc('4')};
          padding-bottom: ${remCalc('8')};
        }
      }

      .img {
        padding: ${remCalc('0 0')};
        img {
          height: ${remCalc('116')};
          width: auto;
        }
      }
    }
  `)}

  ${belowMobile(css`
    a {
      .cont {
        width: 60%;
        padding: ${remCalc('16 8 64 20')};
        h1, h2 {
          br {
            display: inline;
          }
        }
      }
      .img {
        position: absolute;
        bottom: 0;
        right: 0;
        img {
          height: ${remCalc('112')};
        }
      }
    }
  `)}
`;

export const Survey = styled.div`
  ${flexColumnStartY}
  height: ${remCalc('296')};
  background-color: ${blueGrey20};
  border-radius: ${remCalc('8')};
  background-image: url(${ImgSurvey.src});
  background-size: ${remCalc('109')} auto;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding: ${remCalc('28 22')};
  align-items: center;
  gap: ${remCalc('8')};
  position: relative;

  ${belowMdDown(css`
    width: 100%;
    height: fit-content;
    padding: ${remCalc('16 20')};
    background-image: none;
  `)}
`;

export const SurveyHeader = styled(Collapse.Header)`
  h5 {
    ${font(18, 700, '#000000', 1, '140%')}
    text-align: center;
    cursor: default;
    margin-bottom: ${remCalc('8')};
  }

  svg {
    display: none;
  }


  ${belowMdDown(css`
    h5 {
      ${font(16)};
      width: 100%;
      text-align: left;
      cursor: pointer;
    }

    svg {
      display: block;
    }
  `)}
`;

export const SurveyBody = styled(Collapse.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    ${font(14, 400, blueGrey900, 1, '140%')}
    text-align: center;
    letter-spacing: -${remCalc('0.2')};
  }

  ${belowMdDown(css`
    align-items: flex-start;
  `)}
`;

export const SurveyBtn = styled(CustomButton.withComponent(Link))`
  padding: ${remCalc('10 36')};
  margin-top: ${remCalc('20')};
  ${font(13)};

  &:active, &:focus, &:visited {
    color: ${THEME_COLORS.white};
  }

  &:hover {
    background-color: ${THEME_COLORS.black};
  }

  ${belowMdDown(css`
    margin-top: ${remCalc('16')};
  `)}
`;
