import { cardYellow, cardBlue, textCardYellow, textCardBlue } from '@/exportables/styles/colors';

export const TALK_CATEGORY_KEY = {
  general: 'general',
  career: 'career',
};

export interface TalkCategoryType {
  id: number;
  type: keyof typeof TALK_CATEGORY_KEY;
  label: string;
  backgroundColor: string;
  textColor: string;
  borderColor: string;
}

export const TALK_CATEGORY: TalkCategoryType[] = [
  {
    id: 0,
    type: 'general',
    label: '자유주제',
    backgroundColor: cardYellow,
    textColor: textCardYellow,
    borderColor: '#FFE2BF',
  },
  {
    id: 1,
    type: 'career',
    label: '커리어고민',
    backgroundColor: cardBlue,
    textColor: textCardBlue,
    borderColor: '#B4E1F5',
  },
];

export const getCategoryByType = (type: keyof typeof TALK_CATEGORY_KEY): TalkCategoryType => {
  return TALK_CATEGORY.find((item: TalkCategoryType) => item.type === type) || TALK_CATEGORY[0];
};
