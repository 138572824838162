import { Study, Talk } from '../models';

const DESCRIPTION_MAX_LENGTH = 100;

export interface MetaData {
  title: string;
  description: string;
  keywords: string[];
}

export const defaultMetaData: MetaData = {
  title: '프로그래머스 커뮤니티',
  description: '프로그래머스 커뮤니티에서 취업고민, 경력개발, 회사생활, 기술잡담을 나눠보세요. 커뮤니티도 프로그래머스에서.',
  keywords: ['개발자 커뮤니티', '개발자 스터디'],
};

interface MetaInfo {
  [key: string]: Partial<MetaData> | ((...args: any[] | any) => Partial<MetaData>);
  show: ((...args: any[] | any) => Partial<MetaData>);
}

export const talkMeta: MetaInfo = {
  index: {
    title: '개발자 라운지',
    description: defaultMetaData.description,
  },
  write: {
    title: '작성하기',
    description: defaultMetaData.description,
  },
  show: (talk: Talk) => {
    return {
      title: talk.title,
      description: talk.content.slice(0, DESCRIPTION_MAX_LENGTH),
    };
  },
  edit: {
    title: '수정하기',
    description: defaultMetaData.description,
  },
};

export const studyMeta: MetaInfo = {
  index: {
    title: '스터디',
    description: '프로그래머스 커뮤니티에서 개발 스터디를 모집하고 참여해보세요. 함께 할수록 단단하게 성장할 수 있어요. 스터디도 프로그래머스에서.',
  },
  show: (study: Study) => {
    return {
      title: study.title,
      description: study.description.slice(0, DESCRIPTION_MAX_LENGTH),
    };
  },
};
