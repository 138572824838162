import { PropsWithChildren } from 'react';
import { useCollapseContext } from './Collapse.context';
import * as Style from './CollapseHeader.style';
import IcArrowDown from '@/assets/svgs/ic-keyboard-arrow-down.svg';

type CollapseHeaderProps = PropsWithChildren & {
  className?: string,
};

const CollapseHeader = ({ children, className = '' }: CollapseHeaderProps) => {
  const { handleToggle, isShow } = useCollapseContext();

  return (
    <Style.Header
      className={className}
      isShow={isShow}
      onClick={handleToggle}
    >
      {children}
      <IcArrowDown />
    </Style.Header>
  );
};

export default CollapseHeader;
