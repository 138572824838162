import { createContext, useContext } from 'react';

export type CollapseContextType = {
  isShow: boolean,
  handleToggle: () => void,
};

const CollapseContext = createContext<CollapseContextType>(null!);

export const useCollapseContext = () => useContext(CollapseContext);

export default CollapseContext;
