import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import IconThumbsUp from '@/assets/svgs/ic-thumb-up.svg';
import { flexBetweenY, flexContainer, flexStartX, font } from '@/exportables/styles';
import { THEME_COLORS } from '@/exportables/styles/colors';
import { remCalc } from '@/exportables/utils';

const textEllipsisStyle = (line: number) => css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${line};
  text-overflow: ellipsis;
  overflow: hidden;
`;

type ContainerProps = {
  backgroundcolor: CSSStyleDeclaration['backgroundColor'],
  bordercolor: CSSStyleDeclaration['borderColor'],
  color: CSSStyleDeclaration['color'],
};

export const Container = styled(Link)<ContainerProps>`
  ${flexBetweenY}
  height: 100%;
  min-height: ${remCalc('252')};
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  border-radius: ${remCalc('8')};
  gap: ${remCalc('8')};
  padding: ${remCalc('18')};
  border: 1px solid ${({ backgroundcolor }) => backgroundcolor};

  h4 {
    ${font(12, 700)};
    color: ${({ color }) => color};
    margin-bottom: ${remCalc('4')};
  }

  h2 {
    ${flexStartX}
    ${font(16, 700, '#000000', 1, '140%')};
    ${textEllipsisStyle(2)};
    min-height: ${remCalc('40')};
    word-break: keep-all;
  }

  h3 {
    ${font(14, 400, '#777777', 1, '140%')};
    ${textEllipsisStyle(2)};
    min-height: ${remCalc('40')};
    word-break: keep-all;
  }

  &:hover {
    border: 1px solid ${({ bordercolor }) => bordercolor};
  }
`;

export const InfoWrap = styled.div`
  ${flexStartX}
  align-items: center;
  gap: ${remCalc('8')};
  margin: ${remCalc('8 0')};

  img {
    width: ${remCalc('36')};
    height: ${remCalc('36')};
    border-radius: 50%;
    background-color: white;
    overflow: hidden;
    object-fit: contain;
    flex-shrink: 0;
  }

  > p {
    ${flexContainer('flex-start', 'flex-start', 'column')};
    gap: ${remCalc('2')};
    width: calc(100% - ${remCalc('36')});
  }
`;

export const UserName = styled.span`
  ${textEllipsisStyle(1)};
  ${font(14, 400, '#000000', 1, '120%')};
  width: 100%;
`;

export const CreatedAt = styled.span`
  ${font(12, 400, '#777777', 1, '120%')};
`;

export const IconList = styled.ul`
  ${flexStartX};
  gap: ${remCalc('8')};
`;

export const IconWrap = styled.li`
  ${flexStartX};
  ${font(12, 400, THEME_COLORS.textPrimary, 1, '120%')};
  align-items: center;
  gap: ${remCalc('4')};

  &:last-of-type {
    width: 100%;
    justify-content: flex-end;
  }
`;

interface IcStyleProps {
  liked: string;
}

export const IcThumbsUp = styled(IconThumbsUp)<IcStyleProps>`
  cursor: pointer;
  > path {
    fill: ${THEME_COLORS.blueGrey200};
  }
`;

interface NewBadgeProps {
  size?: 'small' | 'large';
}

const CommonBadge = styled.span<NewBadgeProps>`
  ${font(14, 700, THEME_COLORS.white)}
  ${({ size }) => size === 'large' && font(16, 700, THEME_COLORS.white)}

  position: relative;
  display: inline-block;
  text-align: center;
  width: ${({ size }) => size === 'large' ? remCalc('20') : remCalc('16')};
  height: ${({ size }) => size === 'large' ? remCalc('20') : remCalc('16')};
  line-height: ${({ size }) => size === 'large' ? remCalc('20') : remCalc('16')};
  border-radius: ${remCalc('4')};
  background-color: #FF850A;
  margin-right: ${({ size }) => size === 'large' ? remCalc('8') : remCalc('4')};
  top: ${({ size }) => size === 'large' ? remCalc('-2.5') : remCalc('-1')};
`;

export const NewBadge = styled(CommonBadge)`
  background-color: #FF850A;
`;

export const UpdateBadge = styled(CommonBadge)`
  background-color: ${THEME_COLORS.green500};
`;
