import { dehydrate } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';
import React from 'react';
import LayoutLounge from '@/components/layout/LayoutLounge';
import TalkList from '@/components/views/lounge/TalkList/TalkList';
import { queryClient } from '@/exportables/services/queryClient';
import { QUERY_KEY } from '@/exportables/services/queryKey';
import { getTalks } from '@/exportables/services/talk.api';

type Props = {
  slug: string,
};

export const getServerSideProps: GetServerSideProps<{}, Props> = async (ctx) => {
  const slug = ctx.query.slug as string;
  const fetchParams = {
    page: 1,
  };

  await queryClient.prefetchInfiniteQuery([QUERY_KEY.lounge, slug], () => getTalks(slug, fetchParams, ctx));

  return {
    props: {
      slug,
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
    },
  };
};

TalkList.getLayout = function getLayout(page: React.ReactElement) {
  return (
    <LayoutLounge>
      {page}
    </LayoutLounge>
  );
};

export default TalkList;
