import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { NextPageWithLayout } from 'next';
import { Fragment, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import SideBar from '../Sidebar/Sidebar';
import * as Style from './TalkList.style';
import TalkListItem from './TalkListItem';
import SEOHeader from '@/components/common/SEOHeader';
import Spinner from '@/components/common/Spinner';
import { LoungeAsideStyle, LoungeContentStyle } from '@/components/layout/LayoutLounge.style';
import { talkMeta } from '@/exportables/constants/meta';
import { TalkList as TalkListModel } from '@/exportables/models';
import { QUERY_KEY } from '@/exportables/services/queryKey';
import { getTalks } from '@/exportables/services/talk.api';

interface TalkListProps {
  slug: string;
}

const TalkList: NextPageWithLayout<TalkListProps> = ({ slug }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  const fetchProjects = ({ pageParam = 1 }) => {
    return getTalks(slug, { page: pageParam });
  };

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<
  TalkListModel,
  AxiosError,
  TalkListModel
  >({
    queryKey: [QUERY_KEY.lounge, slug],
    queryFn: fetchProjects,
    getNextPageParam: ({ page, totalPages }) => {
      if (Number(page) < totalPages) {
        return Number(page) + 1;
      }
      return null;
    },
  });

  const isEmpty = useMemo(() => {
    return !isFetching && data?.pages?.[0].results.length === 0;
  }, [data, isFetching]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  if (!data) return null;

  return (
    <>
      <SEOHeader {...talkMeta.index} />

      <LoungeContentStyle>
        {data?.pages?.length !== 0 && (
          <Style.List>
            {data?.pages?.map((page) => (
              <Fragment key={page.page}>
                {
                  page?.results?.map((talk) => (
                    <li key={talk.id}>
                      <TalkListItem
                        key={talk.id}
                        item={talk}
                      />
                    </li>
                  ))
                }
              </Fragment>
            ))}
          </Style.List>
        )}
        <div ref={ref} />

        {isEmpty && (
          <Style.Empty>
            <p>아직 등록된 이야기가 없어요. 여러분의 이야기를 들려주세요!</p>
          </Style.Empty>
        )}

        {isFetching && (
          <Style.SpinnerWrapper>
            <Spinner />
          </Style.SpinnerWrapper>
        )}
      </LoungeContentStyle>

      <LoungeAsideStyle>
        <SideBar />
      </LoungeAsideStyle>
    </>
  );
};

export default TalkList;
