import { GetServerSidePropsContext } from 'next';
import { createServerSideClient, heraClient } from './http';
import { Talk, TalkList, TalkCategory } from '@/exportables/models';

export interface PatchTalkPayload {
  title: string;
  content: string;
}

export interface PostTalkPayload extends PatchTalkPayload{
  category: TalkCategory;
}

interface FetchTalksParams {
  category?: TalkCategory;
  page?: number;
}

export const getTalks = async (loungeId: string, params?: FetchTalksParams, ctx?: GetServerSidePropsContext) => {
  const axioClient = ctx ? createServerSideClient(ctx) : heraClient;
  const { data } = await axioClient.get<TalkList>(`/api/community/lounges/${loungeId}/talks`, { params });

  return data;
};

export const createTalk = async (loungeId: number, payload: PostTalkPayload) => {
  const { data } = await heraClient.post<{ talkId: string }>(`/api/community/lounges/${loungeId}/talks`, payload);

  return data;
};

export const getTalk = async (talkId: string, ctx?: GetServerSidePropsContext) => {
  const axioClient = ctx ? createServerSideClient(ctx) : heraClient;
  const { data } = await axioClient.get<Talk>(`/api/community/talks/${talkId}`);

  return data;
};

export const patchTalk = async (talkId: string, payload: PatchTalkPayload) => {
  const { data } = await heraClient.patch<PatchTalkPayload>(`/api/community/talks/${talkId}`, payload);

  return data;
};

export const deleteTalk = async (talkId: string) => {
  const { data } = await heraClient.delete(`/api/community/talks/${talkId}`);

  return data;
};
