import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import CommonButton from '@/components/common/Button';
import { baseContainer, belowLgDown, belowMdDown, belowTablet, flexBetweenX, flexColumnStartY, font, THEME_COLORS } from '@/exportables/styles';
import { remCalc } from '@/exportables/utils';

const asideWidth = 260;

export const LayoutLoungeStyle = styled.div`
  ${baseContainer}
  ${flexBetweenX}
  gap: ${remCalc('64')};
  position: relative;

  ${belowLgDown(css`
    gap: ${remCalc('40')}; 
  `)}

  ${belowMdDown(css`
    flex-direction: column;
    gap: ${remCalc('24')};
  `)} 
`;

export const LayoutHeaderStyle = styled.header`
  ${flexBetweenX}
  width: 100%;
  align-items: center;
  gap: ${remCalc('16')};
  position: absolute;
  top: ${remCalc('48')};
  width: calc(100% - ${remCalc('364')});

  h1 {
    ${font(20, 700, '#000000', 1, '140%')};
    word-break: keep-all;
  }

  ${belowTablet(css`
    width: calc(100% - ${remCalc('372')});
  `)}

  ${belowLgDown(css`
    width: calc(100% - ${remCalc('350')});
  `)}

  ${belowMdDown(css`
    width: 100%;
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: static;
  `)}
`;

export const LoungeContentStyle = styled.main`
  ${flexColumnStartY}
  width: 100%;
  gap: ${remCalc('24')};
  padding: ${remCalc('82 0 0 0')};

  ${belowMdDown(css`
    margin: 0;
    order: 2;
    padding: ${remCalc('0')};
  `)}
`;

export const LoungeAsideStyle = styled.aside`
  ${flexColumnStartY}
  width: ${remCalc(asideWidth + '')};
  gap: ${remCalc('28')};
  flex-shrink: 0;

  ${belowMdDown(css`
    width: 100%;
    gap: ${remCalc('8')};
    order: 1;
  `)}
`;

export const Button = styled(CommonButton.withComponent(Link))`
  flex-shrink: 0;

  svg {
    width: unset;
    height: unset;
  }
  
  &:active, &:focus, &:visited {
    color: ${THEME_COLORS.white}
  }

`;
