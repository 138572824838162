import { useRouter } from 'next/router';
import React from 'react';
import { Button, LayoutLoungeStyle, LayoutHeaderStyle } from './LayoutLounge.style';
import IcKeyboard from '@/assets/svgs/ic-keyboard.svg';

interface Props {
  children: React.ReactNode;
}

const LayoutLounge = ({ children }: Props) => {
  const router = useRouter();
  const { slug: loungeId } = router.query;

  return (
    <LayoutLoungeStyle>
      <LayoutHeaderStyle>
        <h1>프로그래머스 커뮤니티에서 자유롭게 이야기 나눠보세요!</h1>

        <Button
          href={`/lounge/${loungeId}/write`}
          size="large"
          hasIcon
        >
          <IcKeyboard />
          작성하기
        </Button>
      </LayoutHeaderStyle>

      <>
        {children}
      </>

    </LayoutLoungeStyle>
  );
};

export default LayoutLounge;
