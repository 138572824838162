import styled from '@emotion/styled';

export const Body = styled.main`
  width: 100%;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.29, 1);

  &[aria-expanded="false"] {
    pointer-events: none;
  }
`;
