import Image from 'next/image';
import * as Style from './TalkListItem.style';
import defaultProfileImage from '@/assets/images/img-profile-default.jpg';
import IcChat from '@/assets/svgs/ic-chat.svg';
import IcVisibility from '@/assets/svgs/ic-visibility.svg';
import { getCategoryByType } from '@/exportables/constants/talk.constants';
import { TalkListItem as Item } from '@/exportables/models';
import { timeSince } from '@/exportables/utils';

type TalkListItemProps = {
  item: Item,
};

const TalkListItem = ({ item }: TalkListItemProps) => {
  const {
    id,
    title,
    category,
    content,
    userName,
    userProfileImageUrl,
    createdAt,
    talkRepliesCount,
    viewsCount,
    newTalk,
    reaction: {
      liked,
      likesCount,
    },
  } = item;

  const { label, backgroundColor, textColor, borderColor } = getCategoryByType(category);

  return (
    <Style.Container
      href={`/talk/${id}`}
      color={textColor}
      bordercolor={borderColor}
      backgroundcolor={backgroundColor}
    >
      <h4 aria-description="글 카테고리">{label}</h4>
      <h2 aria-description="글 제목">
        {newTalk && <Style.NewBadge>N</Style.NewBadge>}
        <span>{title}</span>
      </h2>
      <h3 aria-description="글 내용">{content}</h3>

      <Style.InfoWrap>
        <Image
          src={userProfileImageUrl || defaultProfileImage}
          alt={`${userName} 프로필 이미지`}
          width={36}
          height={36}
        />
        <p>
          <Style.UserName aria-description="유저 이름">{userName}</Style.UserName>
          <Style.CreatedAt aria-description="글 생성일">
            {timeSince(createdAt)}
          </Style.CreatedAt>
        </p>
      </Style.InfoWrap>

      <Style.IconList>
        <Style.IconWrap aria-description="조회 수">
          <IcVisibility />
          {viewsCount}
        </Style.IconWrap>
        <Style.IconWrap aria-description="댓글 수">
          <IcChat />
          {talkRepliesCount}
        </Style.IconWrap>
        <Style.IconWrap aria-description="좋아요 수">
          <Style.IcThumbsUp liked={liked?.toString()} />
          {likesCount}
        </Style.IconWrap>
      </Style.IconList>
    </Style.Container>
  );
};

export default TalkListItem;
