import { PropsWithChildren, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { useCollapseContext } from './Collapse.context';
import * as Style from './CollapseBody.style';

type CollapseBodyProps = PropsWithChildren & {
  className?: string,
};

const setStyle = (el: HTMLElement, style: Partial<CSSStyleDeclaration>) => {
  Object.assign(el.style, style);
};

const CollapseBody = ({ children, className = '' }: CollapseBodyProps) => {
  const nodeRef = useRef<HTMLElement>(null!);
  const { isShow } = useCollapseContext();

  const onEnter = () => setStyle(nodeRef.current, { opacity: '0', height: '0' });

  const onEntered = () => setStyle(nodeRef.current, { opacity: '1', height: `${nodeRef.current.scrollHeight}px` });

  const onExit = () => setStyle(nodeRef.current, { opacity: '1' });

  const onExiting = () => setStyle(nodeRef.current, { opacity: '0', height: '0' });

  return (
    <Transition
      in={isShow}
      timeout={{
        enter: 0,
        exit: 100,
      }}
      onEnter={onEnter}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
    >
      <Style.Body
        ref={nodeRef}
        className={className}
        aria-expanded={isShow}
      >
        {children}
      </Style.Body>
    </Transition>
  );
};

export default CollapseBody;
