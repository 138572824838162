import styled from '@emotion/styled';
import { CollapseContextType } from '@/components/common/Collapse/Collapse.context';

type HeaderStyleProps = Pick<CollapseContextType, 'isShow'>;

export const Header = styled.header<HeaderStyleProps>`
  width: 100%;
  cursor: pointer;
  position: relative;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.29, 1);

  svg {
    transform: translateY(-50%) rotate(${({ isShow }) => isShow ? '180deg' : 0});
    transition: transform 0.2s ease-in;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
  }
`;
